import axios from 'axios';
import { ArtistFormProps } from '../components/forms/EditArtistDialog';
import { PresetFormProps } from '../components/forms/EditPresetForm';
import { API_URL, PRESET_PACKAGE_NONE } from '../constants';
import { Artist, PostVersion, Preset, StoreItem, Version } from '../types';

async function getArtist(id?: string | number): Promise<Artist | null> {
  if (id === undefined) {
    return null;
  }

  const url = `${API_URL}/v2/admin/artists/${id}/`;

  const res = await axios.get(url);

  const data: Artist = res.data;

  return data;
}

export interface ArtistCreate {
  userRegistrationDto: {
    email: string;
    phoneNumber: string;
    fullName: string;
    password: string;
    instagramUrl: string;
    position: number;
  };
  avatar: File | string;
}

async function createArtist(artist: ArtistFormProps): Promise<Artist> {
  const url = `${API_URL}/v2/admin/artists`;

  const data: ArtistCreate = {
    userRegistrationDto: {
      email: artist.email,
      fullName: artist.name,
      instagramUrl: artist.instagramURL,
      position: artist.position,
      password: '123456',
      phoneNumber: '+77777777777',
    },
    avatar: artist.avatar,
  };

  const formData = new FormData();

  for (const [key, originalValue] of Object.entries(data)) {
    let value = originalValue;

    if (key === 'userRegistrationDto') {
      value = JSON.stringify(value);
    }

    formData.append(key, value);
  }

  const res = await axios.post<Artist>(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return res.data;
}

async function deleteArtist(id: number): Promise<void> {
  const url = `${API_URL}/v2/admin/artists/${id}/?hardDelete=false`;

  await axios.delete(url);
}

interface ArtistUpdate {
  userDto: {
    email: string;
    fullName: string;
    instagramUrl: string;
    position: number;
  };
  avatar?: File;
}

async function updateArtist(
  id: number,
  { avatar, ...artist }: ArtistFormProps
): Promise<Artist> {
  const url = `${API_URL}/v2/admin/artists/${id}`;

  const data: ArtistUpdate = {
    userDto: {
      email: artist.email,
      fullName: artist.name,
      instagramUrl: artist.instagramURL,
      position: artist.position,
    },

    ...(avatar instanceof File && {
      avatar,
    }),
  };

  const formData = new FormData();

  for (const [key, originalValue] of Object.entries(data)) {
    let value = originalValue;

    if (key === 'userDto') {
      value = JSON.stringify(value);
    }

    formData.append(key, value);
  }

  const res = await axios.patch<Artist>(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return res.data;
}

export interface ArtistsResults {
  objects: Artist[];
  page: number;
  size: number;
  pageCount: number;
  lastUpdatedDate: string;
}

async function getArtists(
  size: number = 15,
  page: number = 0,
  query?: string
): Promise<ArtistsResults> {
  const url = `${API_URL}/v2/admin/artists/` + (query ? 'search/' : '');

  const params: { text?: string; size?: number; page?: number } = {};

  if (query) {
    params.text = query;
  }

  if (size !== undefined) {
    params.size = size;
  }

  if (page !== undefined) {
    params.page = page;
  }

  const res = await axios.get(url, { params });

  const data: ArtistsResults = res.data;

  return data;
}

async function restoreArtist({ id, ...artist }: Artist): Promise<void> {
  const url = `${API_URL}/v2/admin/artists/${id}`;

  const formData = new FormData();

  formData.append('userDto', JSON.stringify({ ...artist, state: 'ACTIVE' }));

  await axios.patch(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export interface PresetsResults {
  presets: Preset[];
  page: number;
  pageCount: number;
  size: number;
  lastUpdatedDate: string;
}

interface PresetsQueryParams {
  ownerId?: string;
  size?: number;
  page?: number;
  text?: string;
}

async function getPresets(
  artistId?: string,
  size: number = 15,
  page: number = 0,
  query?: string
): Promise<PresetsResults> {
  const url =
    `${API_URL}/v2/admin/presets` +
    (artistId ? `/owner/` : query ? '/search' : '');

  const params: PresetsQueryParams = {};

  if (query) {
    params.text = query;
  }

  if (artistId !== undefined) {
    params.ownerId = artistId;
  }

  if (size !== undefined) {
    params.size = size;
  }

  if (page !== undefined) {
    params.page = page;
  }

  const res = await axios.get(url, { params });

  const data: PresetsResults = res.data;

  return data;
}

export interface PresetCreate {
  presetCreationDto: {
    name: string;
    ownerUserId: number;
    description: string;
    free: boolean;
    grainSource?: string;
    grainValue?: number;
    storeItemId?: number;
    presetPackageId: null | number;
    style: null | string;
  };
  squareImageFilter?: File | string;
  coverImage?: File | string;
  filterCover?: File | string;
  imageFilter?: File | string;
}

async function createPreset({
  coverBefore,
  coverAfter,
  fileFilter,
  squareImageFilter,
  isFree,
  ...preset
}: PresetFormProps): Promise<any> {
  const url = `${API_URL}/v2/admin/presets`;

  const presetPackage =
    preset.presetPackage === PRESET_PACKAGE_NONE['name']
      ? null
      : preset.presetPackage;

  const data: PresetCreate = {
    coverImage: coverBefore,
    imageFilter: fileFilter,
    filterCover: coverAfter,
    squareImageFilter: squareImageFilter,
    presetCreationDto: {
      name: preset.name,
      description: preset.description,
      free: isFree,
      ownerUserId: preset.artist?.id ?? 0,
      grainSource: preset.grainSource,
      grainValue: preset.grainValue,
      style: preset.style,
      presetPackageId:
        typeof presetPackage === 'string'
          ? null
          : presetPackage && 'id' in presetPackage && presetPackage.id !== -1
          ? presetPackage.id
          : null,
    },
  };

  const formData = new FormData();

  for (const [key, originalValue] of Object.entries(data)) {
    let value = originalValue;

    if (key === 'presetCreationDto') {
      value = JSON.stringify(value);
    }

    formData.append(key, value);
  }

  const res = await axios.post<Preset>(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return res.data;
}

async function deletePreset(id: number): Promise<void> {
  const url = `${API_URL}/v2/admin/presets/${id}/`;

  await axios.delete(url);
}

export interface PresetUpdate {
  presetCreationDto: {
    name: string;
    ownerUserId: number;
    description: string;
    free: boolean;
    grainSource?: string;
    grainValue?: number;
    storeItemId?: number;
    presetPackageId: null | number;
    style: null | string;
  };
  squareImageFilter?: File | string;
  coverImage?: File | string;
  filterCover?: File | string;
  imageFilter?: File | string;
}

async function updatePreset(
  id: number,
  {
    coverBefore,
    coverAfter,
    fileFilter,
    squareImageFilter,
    ...preset
  }: PresetFormProps
): Promise<Preset> {
  const url = `${API_URL}/v2/admin/presets/${id}/`;

  const presetPackage =
    preset.presetPackage === PRESET_PACKAGE_NONE['name']
      ? null
      : preset.presetPackage;

  const data: PresetUpdate = {
    ...(coverBefore instanceof File && { coverImage: coverBefore }),
    ...(coverAfter instanceof File && { filterCover: coverAfter }),
    ...(fileFilter instanceof File && { imageFilter: fileFilter }),

    presetCreationDto: {
      name: preset.name,
      description: preset.description,
      ownerUserId: preset.artist?.id ?? 0,
      grainSource: preset.grainSource,
      grainValue: preset.grainValue,
      free: preset.isFree,
      style: preset.style,
      presetPackageId:
        typeof presetPackage === 'string'
          ? null
          : presetPackage && 'id' in presetPackage && presetPackage.id !== -1
          ? presetPackage.id
          : null,
    },
  };

  const formData = new FormData();

  for (const [key, originalValue] of Object.entries(data)) {
    let value = originalValue;

    if (key === 'presetCreationDto') {
      value = JSON.stringify(value);
    }

    formData.append(key, value);
  }

  const res = await axios.patch<Preset>(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return res.data;
}

async function restorePreset(id: number | string): Promise<void> {
  const url = `${API_URL}/v2/admin/presets/${id}/restore/`;

  await axios.put(url);
}

interface StoreItemsResult {
  objects: StoreItem[];
  page: number;
  size: number;
}

async function getStoreItems(): Promise<StoreItem[]> {
  const url = `${API_URL}/v2/admin/store-items?status=ACTIVE`;

  const res = await axios.get(url);

  const data: StoreItemsResult = res.data;

  return data.objects;
}

export interface PresetPackage {
  id: number;
  name: string;
  coverImageUrl: string;
  description: string;
  presetsNumber: number;
  price: number;
  state: string;
  ownerUserId: number;
}

interface PresetPackagesResults {
  presetPackages: PresetPackage[];
  size: number;
  page: number;
  lastUpdatedDate: string;
  pageCount: number;
}

async function getPresetPackages(): Promise<PresetPackage[]> {
  const url = `${API_URL}/v2/preset-packages`;

  const res = await axios.get(url);

  const data: PresetPackagesResults = res.data;

  return data.presetPackages;
}

async function getVersions(): Promise<Version[]> {
  const url = `${API_URL}/v2/admin/client-version`;

  const res = await axios.get<Version[]>(url);

  return res.data;
}

async function createVersion(data: PostVersion): Promise<Version> {
  const url = `${API_URL}/v2/admin/client-version`;

  const res = await axios.post<Version>(url, data);

  return res.data;
}

async function updateVersion(id: number, data: PostVersion): Promise<Version> {
  const url = `${API_URL}/v2/admin/client-version/${id}`;

  const res = await axios.patch<Version>(url, data);

  return res.data;
}

async function deleteVersion(id: number): Promise<Version> {
  const url = `${API_URL}/v2/admin/client-version/${id}`;

  const res = await axios.delete<Version>(url);

  return res.data;
}

const ApiService = {
  getArtists,
  createArtist,
  deleteArtist,
  updateArtist,
  restoreArtist,
  getPresets,
  getPresetPackages,
  getArtist,
  createPreset,
  deletePreset,
  updatePreset,
  restorePreset,
  getStoreItems,
  getVersions,
  createVersion,
  updateVersion,
  deleteVersion,
};

export default ApiService;

import { Add, Delete, Edit, RestartAlt } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Chip, IconButton, Paper, Tooltip, styled } from '@mui/material';
import { DataGrid, GridCallbackDetails, GridColDef } from '@mui/x-data-grid';
import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import DeleteConfirmDialog from '../components/DeleteConfirmDialog';
import Header from '../components/Header';
import Loader from '../components/Loader';
import EditPresetForm, {
  PresetFormProps,
} from '../components/forms/EditPresetForm';
import withAuth from '../components/withAuth';
import {GRAIN_SOURCES, STYLES} from '../constants';
import ApiService from '../services/ApiService';
import { useAppDispatch } from '../store';
import { setIsAddPresetDialogOpen } from '../store/appSlice';
import { Preset } from '../types';
import renderGridCellAsLink from '../util/renderGridCellAsLink';

const PresetsPageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 auto',

  '.body': {
    margin: theme.spacing(3, 2),
    flex: '1 1 auto',
  },
}));

interface PaginationData {
  page: number;
  size: number;
  pageCount: number;
}

function PresetsPage() {
  const [query] = useSearchParams();
  const { artistId } = useParams();
  const navigate = useNavigate();

  const [initialized, setInitialized] = useState(false);
  const isSingleArtistPage = Boolean(artistId);

  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [activePreset, setActivePreset] = useState<null | Preset>(null);

  const [paginationData, setPaginationData] = useState<PaginationData>({
    page: JSON.parse(query.get('page') ?? '0'),
    size: 14,
    pageCount: 1000,
  });

  const { isLoading, data, refetch } = useQuery(
    ['presets', paginationData.page, artistId],
    () =>
      ApiService.getPresets(artistId, paginationData.size, paginationData.page)
  );

  const { isLoading: isArtistLoading, data: artist } = useQuery(
    `artist-${artistId}`,
    () => ApiService.getArtist(artistId),
    { enabled: isSingleArtistPage }
  );

  const columns: GridColDef[] = [
    {
      field: 'ownerUserId',
      headerName: 'Owner ID',
      minWidth: 60,
      flex: 1,
      maxWidth: 100,
      filterable: false,
      hideable: false,
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      minWidth: 100,
      maxWidth: 150,
      filterable: false,
      hideable: false,
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
      minWidth: 150,
      filterable: false,
      hideable: false,
    },
    {
      field: 'clutImageS3Url',
      headerName: 'Filter URL',
      renderCell: renderGridCellAsLink,
      flex: 1,
      minWidth: 70,
      maxWidth: 100,
      filterable: false,
      hideable: false,
      sortable: false,
    },
    {
      field: 'coverImageS3Url',
      headerName: 'Before URL',
      renderCell: renderGridCellAsLink,
      flex: 1,
      minWidth: 70,
      maxWidth: 100,
      filterable: false,
      sortable: false,
      hideable: false,
    },
    {
      field: 'filteredCoverImageS3Url',
      headerName: 'After URL',
      renderCell: renderGridCellAsLink,
      flex: 1,
      minWidth: 70,
      maxWidth: 100,
      filterable: false,
      hideable: false,
      sortable: false,
    },
    {
      field: 'state',
      headerName: 'Status',
      renderCell: (params) => {
        const value = params.value;

        return <Chip label={value} />;
      },
      flex: 1,
      minWidth: 100,
      maxWidth: 120,
      hideable: false,
    },
    {
      field: 'action',
      headerName: '',
      renderCell: (params) => {
        const value: Preset = params.row;

        return (
          <>
            <Tooltip title="Edit preset">
              <IconButton onClick={onPresetUpdateClick(value)}>
                <Edit />
              </IconButton>
            </Tooltip>
            {value.state === 'DELETED' ? (
              <Tooltip title="Restore preset">
                <LoadingButton
                  color="inherit"
                  onClick={onPresetRestoreClick(value)}
                  title="Restore preset"
                  loading={
                    restorePresetMutation.isLoading &&
                    activePreset?.id === value.id
                  }
                  sx={{ minWidth: 36 }}
                >
                  <RestartAlt />
                </LoadingButton>
              </Tooltip>
            ) : (
              <Tooltip title="Delete preset">
                <IconButton color="error" onClick={onPresetDeleteClick(value)}>
                  <Delete />
                </IconButton>
              </Tooltip>
            )}
          </>
        );
      },
      flex: 1,
      minWidth: 100,
      maxWidth: 100,
      hideable: false,
      sortable: false,
    },
  ];

  const formDefaultValues = useMemo<
    Partial<PresetFormProps> | undefined
  >(() => {
    if (!activePreset) {
      return;
    }

    return {
      name: activePreset.name,
      artistId: activePreset.ownerUserId,
      description: activePreset.description,
      coverBefore: activePreset.coverImageS3Url,
      coverAfter: activePreset.filteredCoverImageS3Url,
      fileFilter: activePreset.clutImageS3Url,
      storeItemId: activePreset.storeItemId,
      grainSource: GRAIN_SOURCES[activePreset.grainSource],
      grainValue: activePreset.grainValue,
      squareImageFilter: activePreset.squareLutS3Url,
      isFree: activePreset.isFree,
    } as Partial<PresetFormProps>;
  }, [activePreset]);

  const presets = data?.presets ?? [];

  const dispatch = useAppDispatch();

  const onPageChange = (newPage: number, details: GridCallbackDetails) => {
    setPaginationData((prev) => ({ ...prev, page: newPage }));
    navigate({ search: `?page=${newPage}` });
  };

  const onAddPresetsClick = () => {
    dispatch(setIsAddPresetDialogOpen(true));
  };

  const onPresetDeleteClick = (preset: Preset) => {
    return () => {
      setIsDeleteConfirmOpen(true);
      setActivePreset(preset);
    };
  };

  const onPresetUpdateClick = (preset: Preset) => {
    return () => {
      dispatch(setIsAddPresetDialogOpen(true));
      setActivePreset(preset);
    };
  };

  const restorePresetMutation = useMutation(
    (id: number) => ApiService.restorePreset(id),
    {
      onSuccess: () => refetch(),
    }
  );

  const onPresetRestoreClick = (preset: Preset) => {
    return () => {
      if (restorePresetMutation.isLoading) {
        return;
      }

      setActivePreset(preset);
      restorePresetMutation.mutate(preset.id);
    };
  };

  useEffect(() => {
    if (!data) {
      return;
    }

    setInitialized(true);
    setPaginationData((prev) => ({
      ...prev,
      pageCount: data.pageCount,
      page: data.page,
    }));
    navigate({ search: `?page=${data.page}` });
  }, [data]);

  return (
    <PresetsPageContainer>
      <DeleteConfirmDialog
        isOpen={isDeleteConfirmOpen}
        title={`Are you sure you want to delete the "${activePreset?.name}" preset?`}
        onMutation={() => ApiService.deletePreset(activePreset?.id ?? 0)}
        onCancel={() => {
          setIsDeleteConfirmOpen(false);
        }}
        onSuccess={() => {
          setIsDeleteConfirmOpen(false);
          setActivePreset(null);
          refetch();
        }}
      />
      <EditPresetForm
        refetch={refetch}
        onClose={() => {
          setActivePreset(null);
        }}
        preset={activePreset ?? undefined}
        defaultValues={formDefaultValues}
      />

      <Header
        title={
          isSingleArtistPage
            ? `${artist?.fullName ?? '...'} - presets`
            : `Presets`
        }
        button={{
          text: 'Add preset',
          startIcon: <Add />,
          onClick: onAddPresetsClick,
        }}
      />
      <Paper elevation={0} className="body">
        {initialized ? (
          <DataGrid
            loading={isLoading}
            rows={presets ? presets : []}
            columns={columns}
            disableSelectionOnClick
            disableColumnMenu
            disableColumnSelector
            paginationMode="server"
            page={paginationData.page}
            pageSize={paginationData.size}
            rowCount={paginationData.pageCount * paginationData.size}
            onPageChange={onPageChange}
          />
        ) : (
          <Loader />
        )}
      </Paper>
    </PresetsPageContainer>
  );
}

export default withAuth(PresetsPage, true, false);
